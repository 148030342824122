@import 'components/App/css/breakpoints'

.Logo__root
  width: 125px
  height: 43px
  background-color: white
  background-image: url("./img/logo.svg")
  background-repeat: no-repeat
  background-position: center

  @media #{$breakpoint-l}, all and (-ms-high-contrast: none)
    background-image: url("./img/logo-m.svg")
    width: 240px
    height: 84px
