@import "@globals/breakpoints"
@import "@globals/grid"
@import "@globals/variables"

$MenuShort-default-iconsize: 2.3rem
$MenuShort-m-iconsize: 2rem
$grid-menu-short: (columns: 6, gutter: 3)

.MenuShort__list
  margin: 0
  padding: 0
  list-style: none
  @include grid-container

  @media #{$breakpoint-m}
    flex-direction: column


.MenuShort__list:before
  @media #{$breakpoint-m}
    content: ""
    display: block
    height: 100%
    width: 1px
    background-color: black
    left: 50%
    position: absolute


.MenuShort__list_item
  margin: 0
  padding: 0
  @include grid(1,2, $grid-menu-short)

  @if $debug == 1
    background-color: rgba(255, 0, 128, 0.3)


  @media #{$breakpoint-m}
    box-sizing: border-box
    z-index: 999
    background-color: white
    border-radius: 50%
    border-style: solid
    border-color: black
    border-width: 1px
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    @include grid(1,6, $grid-menu-short)


.MenuShort__list_item:first-child
  @media #{$breakpoint-m}
    margin-top: 1rem


.MenuShort__list_item:last-child
  @media #{$breakpoint-m}
    margin-bottom: 1rem


.MenuShort__list_item_icon,
.MenuShort__list_item_text
  width: 100%


.MenuShort__list_item_text
  text-align: center
  margin-top: -0.3rem
  @media #{$breakpoint-m}
    display: none


.MenuShort__list_item_icon
  display: flex
  justify-content: center


.MenuShort__list_item_icon_inner
  width: $MenuShort-default-iconsize

  @media #{$breakpoint-m}
    width: $MenuShort-m-iconsize


.MenuShort__list_item_a
  display: block
