$grid-default: (columns: 4, gutter: 11)
$grid-default-m: (columns: 8, gutter: 3)
$grid-default-l: (columns: 14, gutter: 2.5)
$grid-default-xl: (columns: 16, gutter: 2)

$grid-default-loose: (columns: 4, gutter: 11)
$grid-default-loose-m: (columns: 8, gutter: 6)
$grid-default-loose-l: (columns: 14, gutter: 4)
$grid-default-loose-xl: (columns: 16, gutter: 4)


// component Author
$grid-default-author: (columns: 5, gutter: 6.5)

$grid-additions-small-m: (columns: 2, gutter: 3)
$grid-additions-small-l: (columns: 4, gutter: 2.5)
$grid-additions-small-xl: (columns: 7, gutter: 2)

// component Wrapper/Home
$grid-home-content-m: (columns: 6, gutter: 3)
$grid-home-content-l: (columns: 10, gutter: 2.5)
$grid-home-content-xl: (columns: 12, gutter: 2)

// component Issue
$grid-issue-default-m: (columns: 4, gutter: 3)
$grid-issue-default-l: (columns: 4, gutter: 2.5)
$grid-issue-default-xl: (columns: 7, gutter: 2)

@use "sass:selector"
@use "sass:map"

@mixin grid-container($dir: row)
  justify-content: space-between
  flex-wrap: wrap
  display: flex
  flex-direction: row

  @if $dir == col
    flex-direction: column


@mixin grid($from, $to, $config: ("columns": 4, "gutter": 11), $push: false, $append: false)
  max-width: span($from, $to, $config)
  flex-basis: span($from, $to, $config)
  flex-shrink: 1
  flex-grow: 1

  @if $push
    margin-left: span(1, $push, $config)
  @if $append
    margin-right: span(1, $append, $config)

@function span($from, $to, $config)
  $col: map.get($config, "columns")
  $gutter: map.get($config, "gutter")

  $col-count: $to - $from + 1
  $col-width: 100 - $gutter * ($col - 1)
  @return (($col-width / $col) * $col-count + $gutter * ($col-count - 1)) * 1%
