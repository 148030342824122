@import "components/App/css/variables"

.Switch
  font-size: calc($fontScale-default-small * 1rem)
  font-weight: $fontWeights-default-sans-semibold
  display: flex
  align-content: stretch


.Switch__Item
  flex-grow: 1
  position: relative


.Switch input
  position: absolute !important
  left: calc(100% - 1px)
  bottom: 0
  clip: rect(0, 0, 0, 0)
  height: 1px
  width: 1px
  border: 0
  overflow: hidden
  box-shadow: none


.Switch label
  display: block
  background-color: white
  color: gray
  text-align: center
  text-shadow: none
  padding: 0.2em 0.8em
  border: 1px solid black

  &:hover
    cursor: pointer

.Switch input:checked + label
  background-color: black
  color: white
