@import '@globals/grid'
@import '@globals/breakpoints'
@import '@globals/variables'

$--wrapper--xl--max-width: 1920px

.WrapperRoot
  margin: 0 auto

.WrapperMenu
  z-index: 200
  @include grid(1, 4, $grid-default)
  @media #{$breakpoint-l}
    z-index: 1

.WrapperRoot__inner
  padding: 0 2.5%
  margin: 0 auto

  @media #{$breakpoint-m}
    padding: 0 4%

  @media #{$breakpoint-l}
    padding: 0 5%

  @media #{$breakpoint-xl}
    padding: 0 7%
    max-width: $--wrapper--xl--max-width

  @media print
    max-width: 80vw


.WrapperGrid
  @include grid-container

.WrapperContentFullWidth
  @include grid(1, 12)

.WrapperContent
  @include grid(1, 4)

  @media #{$breakpoint-m}
    @include grid(1, 6, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 9, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 8, $grid-default-xl)

.WrapperContentMargin
  @include grid(1, 4, $grid-default)

  @media #{$breakpoint-m}
    @include grid(7, 8, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(11, 14, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(10, 16, $grid-default-xl)

.WrapperMargin
  z-index: 1
  @include grid-container

  @media #{$breakpoint-xl}
    margin-top: 1.5rem
    position: sticky
    top: -320px

.WrapperMarginLeft
  display: none

  @media print
    display: block

  @media #{$breakpoint-m}
    display: block
    @include grid(1, 2, $grid-additions-small-m)

  @media #{$breakpoint-l}
    @include grid(1, 4, $grid-additions-small-l)

  @media #{$breakpoint-xl}
    @include grid(1, 4, $grid-additions-small-xl)

.WrapperMarginRight
  display: none

  @media #{$breakpoint-m}
    display: block
    @include grid(1, 2, $grid-additions-small-m)

  @media #{$breakpoint-l}
    @include grid(1, 4, $grid-additions-small-l)

  @media #{$breakpoint-xl}
    @include grid(5, 7, $grid-additions-small-xl)
