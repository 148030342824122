@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Cookies
  &__wrapper
    position: relative
    z-index: 300

  &__root
    width: 100vw
    height: 100vh
    background: rgba(1, 1, 1, 0.444)
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0

    @include grid-container
    justify-content: flex-end
    align-items: flex-end
    padding: 0

    @media #{$breakpoint-l}
      padding: 6rem


  &__inner_float
    position: fixed
    bottom: 0%
    z-index: 300
    border: 1px solid black

    @media #{$breakpoint-l}
      right: 7%
      bottom: 3.5%

  &__inner
    max-width: 32rem
    background: #fff
    padding: 2rem 2rem

    @media #{$breakpoint-l}
      max-width: 28rem

    div
      width: 100%
      text-align: center
      display: flex
      align-items: center
      justify-content: space-around
      flex-wrap: wrap


    input[type="submit"]
      width: 45%
      margin: 1.5rem 0 0
      color: black

      &:last-of-type
        background-color: #111
        color: white

        &:hover
          background-color: $colors-primary
