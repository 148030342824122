$zIndex-content: 1
$MenuShort-default-iconsize: 2.3rem
$MenuShort-m-iconsize: 2rem
$htmlSizes-default: 16
$htmlSizes-m: 18
$fontSizes-default-base: 16px
$fontSizes-m-base: 18px
$lineHeights-default-base: 1.3
$lineHeights-m-base: 1.65

$fontSizes-default-h1: 35
$fontSizes-default-h2: 20
$fontSizes-default-h3: 16
$fontSizes-default-h4: 16
$fontSizes-m-h1: 48
$fontSizes-m-h2: 21
$fontSizes-m-h3: 18
$fontSizes-m-h4: 18
$lineHeights-default-h1: 40
$lineHeights-m-h1: 60

$zIndex-head: 2
$debug: 0

$fontFamilies-default-sans: Trex-Sans, Helvetica, Arial, sans-serif
$fontWeights-default-sans-light: 300
$fontWeights-default-sans-regular: 400
$fontWeights-default-sans-bold: 700
$fontWeights-default-sans-semibold: 600
$fontScale-default-small: 0.8

$colors-primary: rgb(21, 66, 129)
$colors-gray: rgb(240, 240, 240)
$colors-grayborder: rgb(199, 199, 199)
