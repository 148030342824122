@import "@globals/breakpoints"
@import "@globals/variables"

.Icon__root
  width: 100%
  height: 100%
  display: block

.Icon__root_inline
  display: inline

.Icon__root_flex
  display: flex


.Icon__img
  width: 100%
  height: auto
  display: block

  &_inline
    position: relative
    margin-left: 2px
    top: 4px

    @media #{$breakpoint-m}
      top: 2px

