@import "@globals/breakpoints"

.MenuMain__root
  font-size: 1.7rem
  line-height: 1.8
  text-align: right

  & :global a
    text-decoration: none
    &:hover
      text-decoration: underline

  @media #{$breakpoint-m}
    text-align: center

.MenuMain__list
  list-style: none

  @media #{$breakpoint-m}
    display: flex
    font-size: 0.9rem

.MenuMain__list_item

  @media #{$breakpoint-m}
    padding-right: 1rem

  @media #{$breakpoint-l}
    padding-right: 2rem
