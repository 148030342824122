@import "@globals/breakpoints"
@import "@globals/variables"

$MenuShort-m-iconsize: 2rem
$Menu--default--padding: 1.6rem
$Menu--default--button-diameter: 4rem
$Menu--default--short-height: 6.6rem

.Header__root
  width: 100%
  height: 2rem

  @if $debug == 1
    background-color: rgba(255, 255, 0, 0.1)

  @media #{$breakpoint-m}
    height: 17.6rem

.Menu__root
  position: fixed
  right: $Menu--default--padding
  bottom: $Menu--default--padding
  width: $Menu--default--button-diameter
  height: $Menu--default--button-diameter

  @if $debug == 1
    background-color: rgba(255, 0, 0, 0.5)

  @media #{$breakpoint-m}
    position: relative
    left: 0
    right: 0
    top: 0
    bottom: 0
    width: 100%
    height: 100%


  @media print
    display: none


.Menu__root_open
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: white


.Menu__inner
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%


.Menu__inner_open
  position: absolute

  top: $Menu--default--padding
  left: $Menu--default--padding
  width: calc(100% - $Menu--default--padding * 2)
  height: calc(100% - $Menu--default--padding * 2)

  @media #{$breakpoint-m}
    top: 0
    left: 0
    width: 100%
    height: 100%


.Menu__logo
  width: 100%
  // height: $Menu--default--logo-height
  margin-top: 2rem
  margin-bottom: 3.6rem

  @media #{$breakpoint-m}
    width: auto
    order: 2
    z-index: 999


.Menu__main_content
  margin-top: 3.6rem
  height: 3rem

  @media #{$breakpoint-m}
    order: 1
    z-index: 999
    background-color: white


.Menu__main

  @if $debug == 1
    background-color: rgba(0, 0, 255, 0.5)

  position: absolute
  bottom: calc($Menu--default--short-height)
  height: calc(100% - $Menu--default--short-height)
  width: 100%


.Menu__main_inner
  @media #{$breakpoint-m}
    display: flex
    justify-content: space-between


.Menu__main_inner:before
  @media #{$breakpoint-m}
    content: ""
    display: block
    width: 100%
    height: 1px
    background-color: black
    top: 5.6rem
    position: absolute


.Menu__short
  position: absolute

  @if $debug == 1
    background-color: rgba(0, 255, 0, 0.5)


  bottom: 0
  height: $Menu--default--short-height
  width: calc(100% - (($Menu--default--padding / 2) + $Menu--default--button-diameter))

  @media #{$breakpoint-m}
    right: 0
    height: auto
    width: $MenuShort-m-iconsize
    bottom: auto
    top: 8.5rem


.Menu__content_hidden
  display: none
  @media #{$breakpoint-m}
    display: block


.Menu__caption
  position: absolute
  display: block
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  border: 0


.Menu__icon_bg
  position: absolute
  display: block
  width: 100%
  height: 100%
  top: 0
  left: 0


.Menu__toggle
  position: absolute
  width: $Menu--default--button-diameter
  height: $Menu--default--button-diameter
  right: 0
  bottom: 0
  border-radius: 50%
  border: 1px solid black

  @media #{$breakpoint-m}
    display: none


.Menu__toggle_close
  background-color: black
  color: white
  box-shadow: 0 0 4px 1px #9b9b9b


.Menu__toggle_open
  background-color: white
  color: black

